
.nav-wrapper {
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: -1;
}
  @media only screen and (max-width: 600px) {
    .nav-wrapper {
      width: 100%;
      position: -webkit-sticky; /* Safari */
      position: fixed;
      top: 0;
      background-color: #fff;
      z-index: 100;
    }
    .css-tzssek-MuiSvgIcon-root{
      z-index: 500;
    }
  }
  .grad-bar {
    width: 100%;
    height: 5px;
    background: linear-gradient(-45deg, #e50e46, #1487a0, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    -webkit-animation: gradbar 15s ease infinite;
    -moz-animation: gradbar 15s ease infinite;
    animation: gradbar 15s ease infinite;
  }
  
  .navbar {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    height: 60px;
    overflow: hidden;
  }
  
  .navbar img {
    height: 100px;
    width: 100px;
    justify-self: start;
    margin-left: 20px;
    margin-top: -25px;
  }
  
  .navbar ul {
    list-style: none;
    grid-template-columns: repeat(6, 1fr);
    justify-self: end;
  }
  
  .nav-item a {
    color: #000;
    font-size: 0.9rem;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.5s ease-out;
  }
  
  .nav-item a:hover {
    color: #3498db;
  }
  
  /* SEARCH FUNCTION */
  
  #search-icon {
    font-size: 0.9rem;
    margin-top: 3px;
    margin-left: 15px;
    transition: color 0.3s ease-out;
  }
  
  #search-icon:hover {
    color: #3498db;
    cursor: pointer;
  }
  
  .search {
    transform: translate(-35%);
    -webkit-transform: translate(-35%);
    transition: transform 0.7s ease-in-out;
    color: #3498db;
  }
  
  .no-search {
    transform: translate(0);
    transition: transform 0.7s ease-in-out;
  }
  
  .search-input {
    position: absolute;
    top: -4px;
    right: -125px;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.6s ease;
  }
  
  .search-active {
    opacity: 1;
    z-index: 0;
  }
  
  input {
    border: 0;
    border-left: 1px solid #ccc;
    border-radius: 0; /* FOR SAFARI */
    outline: 0;
    padding: 5px;
  }
  
  /* MOBILE MENU & ANIMATION */
  
  .menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: #3f3f3f;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .menu-toggle {
    justify-self: end;
    margin-right: 25px;
    display: none;
  }
  
  .menu-toggle:hover {
    cursor: pointer;
  }
  
  #mobile-menu.is-active .bar:nth-child(2) {
    opacity: 0;
  }
  
  #mobile-menu.is-active .bar:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    -o-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }
  
  #mobile-menu.is-active .bar:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    -o-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }
  
  /* KEYFRAME ANIMATIONS */
  
  @-webkit-keyframes gradbar {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @-moz-keyframes gradbar {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  @keyframes gradbar {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* Media Queries */
  
  /* Mobile Devices - Phones/Tablets */
  
  @media only screen and (max-width: 720px) {
    .features {
      flex-direction: column;
      padding: 50px;
    }
  
    /* MOBILE HEADINGS */
  
    h1 {
      font-size: 1.9rem;
    }
  
    h2 {
      font-size: 1rem;
    }
  
    p {
      font-size: 0.8rem;
    }
  
    /* MOBILE NAVIGATION */
  
    .navbar ul {
      display: flex;
      flex-direction: column;
      position: fixed;
      justify-content: start;
      top: 65px;
      background-color: #fff;
      width: 100%;
      height: calc(100vh - 55px);
      transform: translate(-101%);
      text-align: center;
      overflow: hidden;
    }
  
    .navbar li {
      padding: 15px;
    }
  
    .navbar li:first-child {
      margin-top: 50px;
    }
  
    .navbar li a {
      font-size: 1rem;
    }
  
    .menu-toggle,
    .bar {
      display: block;
      cursor: pointer;
    }
  
    .mobile-nav {
      transform: translate(0%) !important;
    }
  
    /* SECTIONS */
  
    .headline {
      height: 20vh;
    }
  
    .feature-container p {
      margin-bottom: 25px;
    }
  
    .feature-container {
      margin-top: 20px;
    }
  
    .feature-container:nth-child(2) {
      order: -1;
    }
  
    /* SEARCH DISABLED ON MOBILE */
  
    #search-icon {
      display: none;
    }
  
    .search-input {
      display: none;
    }
  }
  